export const PodcastIcon = ({ fill = "#000" }: { fill?: string }) => (
  <svg
    tabIndex={-1}
    focusable="false"
    className="icon-podcast"
    width="21"
    height="24"
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      tabIndex={-1}
      fill={fill}
      d="M 16.675781 18.605469 C 16.425781 18.78125 16.085938 18.722656 15.898438 18.480469 C 15.699219 18.21875 15.769531 17.839844 16.039062 17.644531 C 18.339844 15.980469 19.832031 13.304688 19.832031 10.285156 C 19.832031 5.234375 15.65625 1.144531 10.5 1.144531 C 5.34375 1.144531 1.167969 5.234375 1.167969 10.285156 C 1.167969 13.179688 2.539062 15.761719 4.683594 17.433594 C 4.949219 17.644531 5.003906 18.03125 4.78125 18.289062 C 4.585938 18.511719 4.246094 18.554688 4.011719 18.375 C 1.570312 16.488281 0 13.566406 0 10.285156 C 0 4.605469 4.699219 0 10.5 0 C 16.300781 0 21 4.605469 21 10.285156 C 21 13.707031 19.296875 16.734375 16.675781 18.605469 Z M 16.675781 18.605469 "
    />
    <path
      tabIndex={-1}
      fill={fill}
      d="M 14.957031 15.574219 C 14.507812 15.9375 13.851562 15.808594 13.5 15.347656 C 13.085938 14.804688 13.273438 14.023438 13.769531 13.546875 C 14.632812 12.71875 15.167969 11.5625 15.167969 10.285156 C 15.167969 7.761719 13.078125 5.714844 10.5 5.714844 C 7.921875 5.714844 5.832031 7.761719 5.832031 10.285156 C 5.832031 11.527344 6.335938 12.652344 7.15625 13.476562 C 7.652344 13.976562 7.816406 14.789062 7.355469 15.320312 C 6.992188 15.742188 6.355469 15.839844 5.929688 15.476562 C 4.441406 14.222656 3.5 12.359375 3.5 10.285156 C 3.5 6.5 6.632812 3.429688 10.5 3.429688 C 14.367188 3.429688 17.5 6.5 17.5 10.285156 C 17.5 12.414062 16.511719 14.316406 14.957031 15.574219 Z M 14.957031 15.574219 "
    />
    <path
      tabIndex={-1}
      fill={fill}
      d="M 12.832031 10.285156 C 12.832031 11.351562 12.089844 12.246094 11.082031 12.5 L 11.082031 17.144531 L 11.28125 17.144531 C 11.453125 17.144531 11.613281 17.214844 11.726562 17.34375 L 16.683594 23.058594 C 17.003906 23.429688 16.734375 24 16.238281 24 L 4.761719 24 C 4.265625 24 3.996094 23.429688 4.316406 23.058594 L 9.273438 17.34375 C 9.386719 17.214844 9.546875 17.144531 9.71875 17.144531 L 9.917969 17.144531 L 9.917969 12.5 C 8.910156 12.246094 8.167969 11.351562 8.167969 10.285156 C 8.167969 9.023438 9.210938 8 10.5 8 C 11.789062 8 12.832031 9.023438 12.832031 10.285156 Z M 12.832031 10.285156 "
    />
  </svg>
);
