import type { Store } from "@inferno/renderer-shared-core";
import { isPWSSessionBackend, PWSSessionState, useNewWindow } from "@inferno/renderer-shared-core";
import { format } from "date-fns";
import { inject } from "mobx-react";
import * as React from "react";
import { SyntheticEvent, useCallback, useEffect } from "react";
import { PlayIcon } from "../../../../../core/components/icons/PlayIcon.component";
import { VolumeInCircleIcon } from "../../../../../core/components/icons/VolumeInCircleIcon.component";
import { Remote } from "../../../../../core/components/remote/Remote.component";
import { coastMediaService } from "../../../../../core/services/Pws";
import { Button, ButtonKind } from "../../../../../core/ui";

interface CoastShowAudioBoxPlayerLinkProps {
  showDate: Date | number;
  store?: Store;
  children?: React.ReactNode;
  showVolumeIcon: boolean;
  frontMatter: boolean;
  episodeSlug?: string;
}

interface CoastShowAudioBoxPlayerLinkLoaderProps {
  showSlugs: string[];
  showDate: Date | number;
  showLoading?: boolean;
  store?: Store;
  children?: React.ReactNode;
  showVolumeIcon: boolean;
  frontMatter: boolean;
}

export const CoastShowAudioBoxPlayerLink = inject("store")(
  ({ showDate, store, showVolumeIcon, frontMatter, episodeSlug = "" }: CoastShowAudioBoxPlayerLinkProps) => {
    const session = store?.session.currentSession;
    const [openWindow, closeWindow] = useNewWindow();

    const handleClick = useCallback(
      (e: SyntheticEvent) => {
        e.preventDefault();
        if (!isPWSSessionBackend(session)) {
          return null;
        }

        session.executeOnValidSubscription(() => {
          openWindow({
            url: `/video-playlist/${format(showDate, "yyyy/MM/d")}/${episodeSlug}`,
            target: "coast-player",
            features: {
              width: 769,
              height: 800,
            },
          });
        });
      },
      [session, openWindow, showDate, episodeSlug],
    );

    const handleStatusChange = useCallback(
      (state: PWSSessionState) => {
        if (!state.authenticated || !state.hasActiveSubscription) {
          closeWindow("coast-player");
        }
      },
      [closeWindow],
    );

    useEffect(() => {
      if (isPWSSessionBackend(session)) {
        session?.onStatusChanged.subscribe(handleStatusChange);
        return () => session?.onStatusChanged.unsubscribe(handleStatusChange);
      }
    }, [session, handleStatusChange]);

    if (!store) {
      return null;
    }

    return (
      <section className="audiobox-section coast-player-link">
        <div className="audiobox-section-value">
          <div className="link-with-icon">
            {showVolumeIcon ? (
              <>
                <Button kind={ButtonKind.CUSTOMIZED} className="media-link volume" click={handleClick}>
                  <VolumeInCircleIcon fill="#fff" />
                </Button>
                <Button kind={ButtonKind.CUSTOMIZED} className="media-link listen" click={handleClick}>
                  Listen with Coast Player
                </Button>
              </>
            ) : (
              <Button
                kind={ButtonKind.CUSTOMIZED}
                className="media-link play-show"
                aria-label={frontMatter ? "Play Show" : "Play"}
                click={handleClick}
              >
                <PlayIcon /> {frontMatter ? <span>Play Show </span> : null}
              </Button>
            )}
          </div>
        </div>
      </section>
    );
  },
);

export const CoastShowAudioBoxPlayerLinkLoader = inject("store")(
  ({
    showSlugs,
    showDate,
    store,
    children,
    showLoading = true,
    showVolumeIcon,
    frontMatter,
  }: CoastShowAudioBoxPlayerLinkLoaderProps) => {
    const session = store?.session.currentSession;

    if (!store || !isPWSSessionBackend(session)) {
      return null;
    }

    return (
      <Remote
        loader={() => coastMediaService.getMediaEpisodesBySlugs(showSlugs, session?.state?.accessToken ?? "")}
        cacheKey={`coast-episodes-playlist-${showDate}-${session.state.hasActiveSubscription}`}
        showLoading={showLoading}
      >
        {/* the episodes themselves don't matter, just the count
                so no need to re-render on login/logout */}
        {({ data }) =>
          data?.videos?.length ? (
            <CoastShowAudioBoxPlayerLink showDate={showDate} showVolumeIcon={showVolumeIcon} frontMatter={frontMatter}>
              {children}
            </CoastShowAudioBoxPlayerLink>
          ) : (
            <>{children}</>
          )
        }
      </Remote>
    );
  },
);
