import { inject } from "mobx-react";

import { formatImage } from "@ihr-radioedit/inferno-core";
import type { FeedResultFragment } from "@ihr-radioedit/inferno-webapi";
import type { Store } from "@inferno/renderer-shared-core";
import { isPublishRecord, RESPONSIVE_IMAGE, reverseRoute } from "@inferno/renderer-shared-core";
import { match, useRouteMatch } from "react-router-dom";
import { PodcastIcon } from "../../../../core/components/icons/PodcastIcon.component";
import { ResponsiveImage } from "../../../../core/components/ResponsiveImage.component";
import { trimText } from "../../../../core/lib/utilities";
import { ButtonKind, FauxButton, Heading, MagicLink } from "../../../../core/ui";
import { getShowZypeSlugs } from "../../lib";
import { CoastFeedItemType, getCoastFeedImg } from "../../lib/coastFeed";
import { CoastShowAudioBoxPlayerLinkLoader } from "../../page-blocks/content/coast-show-audiobox/CoastShowAudioBoxPlayerLink.component";
import { CoastLinkedGuests } from "../CoastLinkedGuests.component";
import { CoastLinkedHost } from "../CoastLinkedHost.component";
import { CoastShowDate } from "./CoastShowDate.component";

interface CoastFeedLastNightShowItemProps {
  item: FeedResultFragment;
  title: string;
  store?: Store;
}

interface LastNightShowMediaHeaderProps {
  showDate: Date | number;
  showSlugs: string[];
  showPodcastIcon: match<object> | null;
}

const LastNightShowMediaHeader = ({ showDate, showSlugs, showPodcastIcon }: LastNightShowMediaHeaderProps) => {
  return (
    <div className="media-header">
      <CoastShowAudioBoxPlayerLinkLoader
        showSlugs={showSlugs}
        showDate={showDate}
        showLoading={false}
        showVolumeIcon={true}
        frontMatter={false}
      />
      {!showPodcastIcon ? (
        <FauxButton to="/podcast-feed/" className="podcast_icon" kind={ButtonKind.CUSTOMIZED} title="Podcast">
          <PodcastIcon fill="#fff" />
        </FauxButton>
      ) : null}
    </div>
  );
};

export const CoastFeedLastNightShowItem = inject("store")(({ item, store, title }: CoastFeedLastNightShowItemProps) => {
  if (!store || !isPublishRecord(item.record)) {
    return null;
  }

  const { env } = store;
  const { slug, payload, pub_start } = item.record;
  const { summary } = payload;

  const thumb = formatImage(summary.image.url, env.IMAGE_HOST);
  const { imgSrc, srcset } = getCoastFeedImg(thumb, CoastFeedItemType.SHOW, { quality: 80 });

  const showUrl = reverseRoute(store.site, "shows_detail", { slug }) || "#";
  const showDate = payload.fields.show_date.value || pub_start;
  const slugs = getShowZypeSlugs(payload.fields);
  const podcastFeedRouteMatch = useRouteMatch({ path: "/podcast-feed", exact: true });

  return (
    <>
      <Heading level={3}>
        {title}
        <LastNightShowMediaHeader showDate={showDate} showSlugs={slugs} showPodcastIcon={podcastFeedRouteMatch} />
      </Heading>

      <div className="coast-feed-item">
        <MagicLink to={showUrl} context={slug} className="item-thumb-container">
          <figure className="item-thumb">
            <ResponsiveImage
              alt={summary.image.alt_text || summary.title}
              src={imgSrc}
              srcset={srcset}
              initialWidth={RESPONSIVE_IMAGE.responsiveImageInitialWidth}
              initialHeight={RESPONSIVE_IMAGE.responsiveImageInitialHeight}
            />
            <figcaption className="short-date">
              <CoastShowDate item={item.record} isShortDate={true} store={store} />

              <span className="item-title">{summary.title}</span>
            </figcaption>
          </figure>
        </MagicLink>

        <section className="item-links">
          <CoastLinkedHost titleOnSameLine={true} item={item.record} />
          <CoastLinkedGuests titleOnSameLine={true} item={item.record} />
        </section>

        <section className="item-summary">{trimText(summary.description, 280)}</section>
      </div>

      <div className="more-container">
        <MagicLink context={slug} className="more" to={showUrl}>
          More &raquo;
        </MagicLink>
      </div>
    </>
  );
});

export default CoastFeedLastNightShowItem;
