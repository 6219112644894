import * as io from "io-ts";
import { PayloadFieldsBaseResolver, PayloadResolver } from "@ihr-radioedit/inferno-core";
import {
  MaybeAuthorFieldResolver,
  MaybeBumperMusicFieldResolver,
  MaybeDateFieldResolver,
  MaybeHtmlFieldResolver,
  MaybeLinkFieldResolver,
  MaybePlainFieldResolver,
  CoastArticlePayloadResolver,
} from "@ihr-radioedit/inferno-core";

export const CoastShowPayloadFieldsResolver = io.intersection([
  PayloadFieldsBaseResolver,
  io.type({
    articles: MaybeHtmlFieldResolver,
    author: MaybeAuthorFieldResolver,
    books: MaybeHtmlFieldResolver,
    highlights: MaybePlainFieldResolver,
    episodes: MaybePlainFieldResolver,
    zype_episodes: MaybePlainFieldResolver,
    host_name: MaybePlainFieldResolver,
    bumper_music: MaybeBumperMusicFieldResolver,
    show_date: MaybeDateFieldResolver,
    videos: MaybeHtmlFieldResolver,
    websites: MaybeHtmlFieldResolver,
    guest: MaybeLinkFieldResolver,
    host: MaybeLinkFieldResolver,
  }),
]);
export type CoastShowPayloadFields = io.TypeOf<typeof CoastShowPayloadFieldsResolver>;

export const CoastShowPayloadResolver = io.intersection([
  PayloadResolver,
  io.type({
    fields: CoastShowPayloadFieldsResolver,
  }),
]);
export type CoastShowPayload = io.TypeOf<typeof CoastShowPayloadResolver>;

export const CoastGuestPayloadFieldsResolver = io.intersection([
  PayloadFieldsBaseResolver,
  io.type({
    cds: MaybeHtmlFieldResolver,
    websites: MaybeHtmlFieldResolver,
    books: MaybeHtmlFieldResolver,
    videos: MaybeHtmlFieldResolver,
  }),
]);
export type CoastGuestPayloadFields = io.TypeOf<typeof CoastGuestPayloadFieldsResolver>;

export const CoastGuestPayloadResolver = io.intersection([
  PayloadResolver,
  io.type({
    fields: CoastGuestPayloadFieldsResolver,
  }),
]);
export type CoastGuestPayload = io.TypeOf<typeof CoastGuestPayloadResolver>;

export type CoastArticlePayloadFields = io.TypeOf<typeof CoastGuestPayloadFieldsResolver>;

export type CoastArticlePayload = io.TypeOf<typeof CoastArticlePayloadResolver>;
