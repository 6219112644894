import classNames from "classnames";
import { inject } from "mobx-react";
import { useMemo } from "react";

import type { PublishRecordFragment } from "@ihr-radioedit/inferno-webapi";
import { reverseRoute } from "@inferno/renderer-shared-core";
import { MagicLink } from "../../../core/ui";
import { getCoastLinks } from "../lib";
import "./CoastLinkedItems.style.scss";
import { CoastShowPayloadResolver } from "../../coast/page-blocks/content/CoastContent.types";
import { isLeft } from "fp-ts/lib/These";
import { getPaths } from "@ihr-radioedit/inferno-core";
import { ILog } from "@ihr-radioedit/inferno-core";
import type { Store } from "@inferno/renderer-shared-core";

const log = ILog.logger("CoastLinkedHost");

interface CoastLinkedHostProps {
  item: PublishRecordFragment;
  titleOnSameLine: boolean;
  store?: Store;
}

export const CoastLinkedHost = inject("store")(({ store, item, titleOnSameLine }: CoastLinkedHostProps) => {
  if (!store) {
    return null;
  }

  const { payload } = item;
  const resolvedPayload = CoastShowPayloadResolver.decode(payload);

  if (isLeft(resolvedPayload)) {
    log.error(`Payload failed validation: ${getPaths(resolvedPayload)}`);
    return null;
  }

  const { fields } = resolvedPayload.right;
  const hostname = fields.host_name?.value;

  const host = useMemo(() => getCoastLinks(item, "hosts")?.[0], [item]);

  if (!host && !hostname) {
    return null;
  }

  const hostUrl = reverseRoute(store.site, "hosts_detail", { slug: host?.slug }) || "#";

  const className = classNames("coast-linked-host coast-linked-items", { "title-on-same-line": titleOnSameLine });

  return (
    <p className={className}>
      {host ? (
        <>
          <span className="linked-header">Hosted by{titleOnSameLine ? ":" : ""}</span>
          <span className="linked-value">
            <MagicLink to={hostUrl} context={host?.slug}>
              {host?.summary.title}
            </MagicLink>
          </span>
        </>
      ) : (
        <>
          <span className="linked-header">Hosted by{titleOnSameLine ? ":" : ""}</span>
          <span className="linked-value">{fields.host_name?.value}</span>
        </>
      )}
    </p>
  );
});
