export const VolumeInCircleIcon = ({ fill = "#000" }: { fill?: string }) => (
  <svg
    tabIndex={-1}
    focusable="false"
    className="icon-volume-in-circle"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    id="Layer_1"
  >
    <path
      tabIndex={-1}
      d="M12,23.46A11.34,11.34,0,0,1,.77,12,11.34,11.34,0,0,1,12,.61,11.33,11.33,0,0,1,23.26,12,11.34,11.34,0,0,1,12,23.46ZM12,1.62A10.32,10.32,0,0,0,1.77,12,10.33,10.33,0,0,0,12,22.45,10.33,10.33,0,0,0,22.27,12,10.33,10.33,0,0,0,12,1.62Z"
      fill={fill}
      transform="translate(-0.27 -0.11)"
    />
    <path
      tabIndex={-1}
      d="M5,10.44v3.19a.8.8,0,0,0,.79.8H8.09l2.58,2.62A.79.79,0,0,0,12,16.48V7.58A.79.79,0,0,0,10.67,7L8.09,9.64H5.74A.8.8,0,0,0,5,10.44ZM15.55,12a3.58,3.58,0,0,0-2-3.21v6.42A3.56,3.56,0,0,0,15.55,12Zm-2-6v.16a.74.74,0,0,0,.48.68,5.62,5.62,0,0,1,0,10.37.72.72,0,0,0-.48.68v.15a.71.71,0,0,0,.95.68,7.2,7.2,0,0,0,0-13.39A.7.7,0,0,0,13.58,6Z"
      fill={fill}
      transform="translate(-0.27 -0.11)"
    />
  </svg>
);
